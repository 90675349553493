import React from 'react';
import { useTheme } from '../common/ThemeContext';

import Publicite from "./Publicite";
import Contenu from "./Culture";
import Voyage from "./Voyage";
import Tourisme from "./tourisme";
import YoutbeVideo from "./adds";







const Home = () => {
    const { isDarkMode } = useTheme();

    return ( 
        <>

           <Publicite/> 
           <Contenu/>
           <Voyage/>
           <Tourisme/>
           <YoutbeVideo VideoID="sbDPc11LT70" />
          
        </>
     );
}
 
export default Home;