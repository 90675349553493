

//les donnes pour les menus qui commencent par Acceuil
export const menu1=[
    {
        id:1,
        url:'/',
        text:'Accueil',
        
    },
    {
        id:2,
        url:'/culture',
        text:'Culture',
        
        
    },
    {
        id:3,
        url:'/voyage',
        text:'Voyage',
       
    },
    {
        id:4,
        url:'/tourisme',
        text:'Tourisme',
    },
    {
        id:5,
        url:'/publicite',
        text:'publicité',
    },
    {
        id:6,
        url:'propos',
        text:'A Propos',
    },
] 


 
//les images qui commencent apres le header
 export const start = [
    {
        id:1,
        title:"Bienvenue Sur Travel Tourism And Culture Vision",
        description:"Lorem ipsum dolor sit amet consectetur adipisicing elit.Aperiam magnam excepturi esse. Ut nam dolores qui enim, quonobis reiciendis!Lorem ipsum dolor sit amet consectetur adipisicing elit.Aperiam magnam excepturi esse. Ut nam dolores qui enim, quonobis reiciendis!",
        boutton1:"Get started",
        boutton2:"Voir plus details",
        image:"./images/i4.jpg",
    },
    {
        id:2,
        title:"Bienvenue Sur Travel Tourism And Culture Vision",
        description:"Lorem ipsum dolor sit amet consectetur adipisicing elit.Aperiam magnam excepturi esse. Ut nam dolores qui enim, quonobis reiciendis!Lorem ipsum dolor sit amet consectetur adipisicing elit.Aperiam magnam excepturi esse. Ut nam dolores qui enim, quonobis reiciendis!",
        boutton1:"Get started",
        boutton2:"Voir plus details",
        image:"./images/i11.jpg",
    }, {
        id:3,
        title:"Bienvenue Sur Travel Tourism And Culture Vision",
        description:"Lorem ipsum dolor sit amet consectetur adipisicing elit.Aperiam magnam excepturi esse. Ut nam dolores qui enim, quonobis reiciendis!Lorem ipsum dolor sit amet consectetur adipisicing elit.Aperiam magnam excepturi esse. Ut nam dolores qui enim, quonobis reiciendis!",
        boutton1:"Get started",
        boutton2:"Voir plus details",
        image:"./images/i15.jpg",
    },
    
]

//les donnees pour la categories culture
export const culture = [
    {
        id:"1",
        title:"Lorem ipsum dolor sit amet consectetur ",
        description:"Lorem ipsum dolor sit amet consectetur adipisicing elit.Aperiam Lorem ipsum dolor sit amet consectetur adipisicing elit.AperiamLorem ipsum dolor sit amet consectetur adipisicing elit.AperiamLorem ipsum dolor sit amet consectetur adipisicing elit.AperiamvLorem ipsum dolor sit amet consectetur adipisicing elit.Aperiam",
        boutton1:"Get started",
        boutton2:"Voir plus details",
        image:"./images/i10.jpg",
        user:"Mutima",
        profile:"Journaliste",
        date:"12/4/2024",
        categorie:"culture",
        premium:"premium",
        localisation:"Gitega , Burundi",
    },
    {
        id:"2",
        title:"Lorem ipsum dolor sit amet consectetur ",
        description:"Lorem ipsum dolor sit amet consectetur adipisicing elit.Aperiam Lorem ipsum dolor sit amet consectetur adipisicing elit.AperiamLorem ipsum dolor sit amet consectetur adipisicing elit.AperiamLorem ipsum dolor sit amet consectetur adipisicing elit.AperiamvLorem ipsum dolor sit amet consectetur adipisicing elit.Aperiam",
        boutton1:"Get started",
        boutton2:"Voir plus details",
        image:"./images/i3.jpg",
        user:"Mutima",
        profile:"Journaliste",
        date:"12/4/2024",
        categorie:"culture",
        localisation:"Gitega , Burundi",
    },
   

   
    
]
//les donnees pour la categories Travel
export const travel = [
    {
        id:"1",
        title:"Lorem ipsum dolor sit amet consectetur adipisicing elitAperiam",
        description:"Lorem ipsum dolor sit amet consectetur adipisicing elit.Aperiam ...",
        boutton1:"Get started",
        boutton2:"Voir plus details",
        image:"./images/i16.jpg",
        user:"Mutima",
        profile:"Journaliste",
        date:"12/4/2024",
        categorie:"Tourisme",
        premium:"premium",
        localisation:"Gitega , Burundi",
    },
    {
        id:"2",
        title:"Lorem ipsum dolor sit amet consectetur adipisicing elitAperiam",
        description:"Lorem ipsum dolor sit amet consectetur adipisicing elit.Aperiam ...",
        boutton1:"Get started",
        boutton2:"Voir plus details",
        image:"./images/i11.jpg",
        user:"Mutima",
        profile:"Journaliste",
        date:"12/4/2024",
        categorie:"Tourisme",
        localisation:"Gitega , Burundi",
    },
    
   
    
]

//les donnees pour la categorie Voyage
export const voyage = [
    {
        id:"1",
        title:"Lorem ipsum dolor sit amet consectetur adipisicing elitAperiam",
        description:"Lorem ipsum dolor sit amet consectetur adipisicing elit.Aperiam ...",
        boutton1:"Get started",
        boutton2:"Voir plus details",
        image:"./images/i16.jpg",
        user:"Mutima",
        profile:"Journaliste",
        date:"12/4/2024",
        categorie:"voyage",
        premium:"premium",
        localisation:"Gitega , Burundi",
    },
    {
        id:"2",
        title:"Lorem ipsum dolor sit amet consectetur adipisicing elitAperiam",
        description:"Lorem ipsum dolor sit amet consectetur adipisicing elit.Aperiam ...",
        boutton1:"Get started",
        boutton2:"Voir plus details",
        image:"./images/i11.jpg",
        user:"Mutima",
        profile:"Journaliste",
        date:"12/4/2024",
        categorie:"voyage",
        localisation:"Gitega , Burundi",
    },
   

   
    
]


//donnees reel
export const culture1= [
    {
        id:"1",
        title:"Lorem ipsum dolor sit amet consectetur ",
        description:"Lorem ipsum dolor sit amet consectetur adipisicing elit.Aperiam Lorem ipsum dolor sit amet consectetur adipisicing elit.AperiamLorem ipsum dolor sit amet consectetur adipisicing elit.AperiamLorem ipsum dolor sit amet consectetur adipisicing elit.AperiamvLorem ipsum dolor sit amet consectetur adipisicing elit.Aperiam",
        boutton1:"Get started",
        boutton2:"Voir plus details",
        image:"./images/i10.jpg",
        user:"Mutima",
        profile:"Journaliste",
        date:"12/4/2024",
        categorie:"culture",
        pre:"premium",
        localisation:"Gitega , Burundi",
    },
    {
        id:"2",
        title:"Lorem ipsum dolor sit amet consectetur ",
        description:"Lorem ipsum dolor sit amet consectetur adipisicing elit.Aperiam Lorem ipsum dolor sit amet consectetur adipisicing elit.AperiamLorem ipsum dolor sit amet consectetur adipisicing elit.AperiamLorem ipsum dolor sit amet consectetur adipisicing elit.AperiamvLorem ipsum dolor sit amet consectetur adipisicing elit.Aperiam",
        boutton1:"Get started",
        boutton2:"Voir plus details",
        image:"./images/i13.jpg",
        user:"Mutima",
        profile:"Journaliste",
        pre:"sponsorisé",
        date:"12/4/2024",
        categorie:"culture",
        localisation:"Gitega , Burundi",
    },
    {
        id:"3",
        title:"Lorem ipsum dolor sit amet consectetur ",
        description:"Lorem ipsum dolor sit amet consectetur adipisicing elit.Aperiam Lorem ipsum dolor sit amet consectetur adipisicing elit.AperiamLorem ipsum dolor sit amet consectetur adipisicing elit.AperiamLorem ipsum dolor sit amet consectetur adipisicing elit.AperiamvLorem ipsum dolor sit amet consectetur adipisicing elit.Aperiam",
        boutton1:"Get started",
        boutton2:"Voir plus details",
        image:"./images/i4.jpg",
        user:"Mutima",
        profile:"Journaliste",
        pre:"gratuit",
        date:"12/4/2024",
        categorie:"culture",
        localisation:"Gitega , Burundi",
    },
    {
        id:"4",
        title:"Lorem ipsum dolor sit amet consectetur ",
        description:"Lorem ipsum dolor sit amet consectetur adipisicing elit.Aperiam Lorem ipsum dolor sit amet consectetur adipisicing elit.AperiamLorem ipsum dolor sit amet consectetur adipisicing elit.AperiamLorem ipsum dolor sit amet consectetur adipisicing elit.AperiamvLorem ipsum dolor sit amet consectetur adipisicing elit.Aperiam",
        boutton1:"Get started",
        boutton2:"Voir plus details",
        image:"./images/i2.jpg",
        user:"Mutima",
        pre:"premium",
        profile:"Journaliste",
        date:"12/4/2024",
        categorie:"culture",
        localisation:"Gitega , Burundi",
    },
    {
        id:"5",
        title:"Lorem ipsum dolor sit amet consectetur ",
        description:"Lorem ipsum dolor sit amet consectetur adipisicing elit.Aperiam Lorem ipsum dolor sit amet consectetur adipisicing elit.AperiamLorem ipsum dolor sit amet consectetur adipisicing elit.AperiamLorem ipsum dolor sit amet consectetur adipisicing elit.AperiamvLorem ipsum dolor sit amet consectetur adipisicing elit.Aperiam",
        boutton1:"Get started",
        boutton2:"Voir plus details",
        image:"./images/i12.jpeg",
        user:"Mutima",
        profile:"Journaliste",
        pre:"sposorisé",
        date:"12/4/2024",
        categorie:"culture",
        localisation:"Gitega , Burundi",
    },
    {
        id:"6",
        title:"Lorem ipsum dolor sit amet consectetur ",
        description:"Lorem ipsum dolor sit amet consectetur adipisicing elit.Aperiam Lorem ipsum dolor sit amet consectetur adipisicing elit.AperiamLorem ipsum dolor sit amet consectetur adipisicing elit.AperiamLorem ipsum dolor sit amet consectetur adipisicing elit.AperiamvLorem ipsum dolor sit amet consectetur adipisicing elit.Aperiam",
        boutton1:"Get started",
        boutton2:"Voir plus details",
        image:"./images/i15.jpg",
        user:"Mutima",
        profile:"Journaliste",
        pre:"sposorisé",
        date:"12/4/2024",
        categorie:"culture",
        localisation:"Gitega , Burundi",
    },
    {
        id:"7",
        title:"Lorem ipsum dolor sit amet consectetur ",
        description:"Lorem ipsum dolor sit amet consectetur adipisicing elit.Aperiam Lorem ipsum dolor sit amet consectetur adipisicing elit.AperiamLorem ipsum dolor sit amet consectetur adipisicing elit.AperiamLorem ipsum dolor sit amet consectetur adipisicing elit.AperiamvLorem ipsum dolor sit amet consectetur adipisicing elit.Aperiam",
        boutton1:"Get started",
        boutton2:"Voir plus details",
        image:"./images/i16.jpg",
        user:"Mutima",
        profile:"Journaliste",
        date:"12/4/2024",
        categorie:"culture",
        localisation:"Gitega , Burundi",
    },
    {
        id:"8",
        title:"Lorem ipsum dolor sit amet consectetur ",
        description:"Lorem ipsum dolor sit amet consectetur adipisicing elit.Aperiam Lorem ipsum dolor sit amet consectetur adipisicing elit.AperiamLorem ipsum dolor sit amet consectetur adipisicing elit.AperiamLorem ipsum dolor sit amet consectetur adipisicing elit.AperiamvLorem ipsum dolor sit amet consectetur adipisicing elit.Aperiam",
        boutton1:"Get started",
        boutton2:"Voir plus details",
        image:"./images/i1.jpg",
        user:"Mutima",
        profile:"Journaliste",
        date:"12/4/2024",
        categorie:"culture",
        localisation:"Gitega , Burundi",
    },
    {
        id:"9",
        title:"Lorem ipsum dolor sit amet consectetur ",
        description:"Lorem ipsum dolor sit amet consectetur adipisicing elit.Aperiam Lorem ipsum dolor sit amet consectetur adipisicing elit.AperiamLorem ipsum dolor sit amet consectetur adipisicing elit.AperiamLorem ipsum dolor sit amet consectetur adipisicing elit.AperiamvLorem ipsum dolor sit amet consectetur adipisicing elit.Aperiam",
        boutton1:"Get started",
        boutton2:"Voir plus details",
        image:"./images/i10.jpg",
        user:"Mutima",
        profile:"Journaliste",
        date:"12/4/2024",
        categorie:"culture",
        localisation:"Gitega , Burundi",
    },
    {
        id:"10",
        title:"Lorem ipsum dolor sit amet consectetur ",
        description:"Lorem ipsum dolor sit amet consectetur adipisicing elit.Aperiam Lorem ipsum dolor sit amet consectetur adipisicing elit.AperiamLorem ipsum dolor sit amet consectetur adipisicing elit.AperiamLorem ipsum dolor sit amet consectetur adipisicing elit.AperiamvLorem ipsum dolor sit amet consectetur adipisicing elit.Aperiam",
        boutton1:"Get started",
        boutton2:"Voir plus details",
        image:"./images/i8.png",
        user:"Mutima",
        profile:"Journaliste",
        date:"12/4/2024",
        categorie:"culture",
        localisation:"Gitega , Burundi",
    },
    {
        id:"11",
        title:"Lorem ipsum dolor sit amet consectetur ",
        description:"Lorem ipsum dolor sit amet consectetur adipisicing elit.Aperiam Lorem ipsum dolor sit amet consectetur adipisicing elit.AperiamLorem ipsum dolor sit amet consectetur adipisicing elit.AperiamLorem ipsum dolor sit amet consectetur adipisicing elit.AperiamvLorem ipsum dolor sit amet consectetur adipisicing elit.Aperiam",
        boutton1:"Get started",
        boutton2:"Voir plus details",
        image:"./images/i7.jpg",
        user:"Mutima",
        profile:"Journaliste",
        date:"12/4/2024",
        categorie:"culture",
        localisation:"Gitega , Burundi",
    },
    {
        id:"12",
        title:"Lorem ipsum dolor sit amet consectetur ",
        description:"Lorem ipsum dolor sit amet consectetur adipisicing elit.Aperiam Lorem ipsum dolor sit amet consectetur adipisicing elit.AperiamLorem ipsum dolor sit amet consectetur adipisicing elit.AperiamLorem ipsum dolor sit amet consectetur adipisicing elit.AperiamvLorem ipsum dolor sit amet consectetur adipisicing elit.Aperiam",
        boutton1:"Get started",
        boutton2:"Voir plus details",
        image:"./images/i1.jpg",
        user:"Mutima",
        profile:"Journaliste",
        date:"12/4/2024",
        categorie:"culture",
        localisation:"Gitega , Burundi",
    },

    {
        id:"13",
        title:"Lorem ipsum dolor sit amet consectetur ",
        description:"Lorem ipsum dolor sit amet consectetur adipisicing elit.Aperiam Lorem ipsum dolor sit amet consectetur adipisicing elit.AperiamLorem ipsum dolor sit amet consectetur adipisicing elit.AperiamLorem ipsum dolor sit amet consectetur adipisicing elit.AperiamvLorem ipsum dolor sit amet consectetur adipisicing elit.Aperiam",
        boutton1:"Get started",
        boutton2:"Voir plus details",
        image:"./images/i10.jpg",
        user:"Mutima",
        profile:"Journaliste",
        date:"12/4/2024",
        categorie:"culture",
        premium:"premium",
        localisation:"Gitega , Burundi",
    },
    {
        id:"14",
        title:"Lorem ipsum dolor sit amet consectetur ",
        description:"Lorem ipsum dolor sit amet consectetur adipisicing elit.Aperiam Lorem ipsum dolor sit amet consectetur adipisicing elit.AperiamLorem ipsum dolor sit amet consectetur adipisicing elit.AperiamLorem ipsum dolor sit amet consectetur adipisicing elit.AperiamvLorem ipsum dolor sit amet consectetur adipisicing elit.Aperiam",
        boutton1:"Get started",
        boutton2:"Voir plus details",
        image:"./images/i13.jpg",
        user:"Mutima",
        profile:"Journaliste",
        date:"12/4/2024",
        categorie:"culture",
        localisation:"Gitega , Burundi",
    },
    {
        id:"15",
        title:"Lorem ipsum dolor sit amet consectetur ",
        description:"Lorem ipsum dolor sit amet consectetur adipisicing elit.Aperiam Lorem ipsum dolor sit amet consectetur adipisicing elit.AperiamLorem ipsum dolor sit amet consectetur adipisicing elit.AperiamLorem ipsum dolor sit amet consectetur adipisicing elit.AperiamvLorem ipsum dolor sit amet consectetur adipisicing elit.Aperiam",
        boutton1:"Get started",
        boutton2:"Voir plus details",
        image:"./images/i4.jpg",
        user:"Mutima",
        profile:"Journaliste",
        date:"12/4/2024",
        categorie:"culture",
        localisation:"Gitega , Burundi",
    },
    {
        id:"16",
        title:"Lorem ipsum dolor sit amet consectetur ",
        description:"Lorem ipsum dolor sit amet consectetur adipisicing elit.Aperiam Lorem ipsum dolor sit amet consectetur adipisicing elit.AperiamLorem ipsum dolor sit amet consectetur adipisicing elit.AperiamLorem ipsum dolor sit amet consectetur adipisicing elit.AperiamvLorem ipsum dolor sit amet consectetur adipisicing elit.Aperiam",
        boutton1:"Get started",
        boutton2:"Voir plus details",
        image:"./images/i2.jpg",
        user:"Mutima",
        profile:"Journaliste",
        date:"12/4/2024",
        categorie:"culture",
        localisation:"Gitega , Burundi",
    },
    {
        id:"17",
        title:"Lorem ipsum dolor sit amet consectetur ",
        description:"Lorem ipsum dolor sit amet consectetur adipisicing elit.Aperiam Lorem ipsum dolor sit amet consectetur adipisicing elit.AperiamLorem ipsum dolor sit amet consectetur adipisicing elit.AperiamLorem ipsum dolor sit amet consectetur adipisicing elit.AperiamvLorem ipsum dolor sit amet consectetur adipisicing elit.Aperiam",
        boutton1:"Get started",
        boutton2:"Voir plus details",
        image:"./images/i12.jpeg",
        user:"Mutima",
        profile:"Journaliste",
        date:"12/4/2024",
        categorie:"culture",
        localisation:"Gitega , Burundi",
    },
    {
        id:"18",
        title:"Lorem ipsum dolor sit amet consectetur ",
        description:"Lorem ipsum dolor sit amet consectetur adipisicing elit.Aperiam Lorem ipsum dolor sit amet consectetur adipisicing elit.AperiamLorem ipsum dolor sit amet consectetur adipisicing elit.AperiamLorem ipsum dolor sit amet consectetur adipisicing elit.AperiamvLorem ipsum dolor sit amet consectetur adipisicing elit.Aperiam",
        boutton1:"Get started",
        boutton2:"Voir plus details",
        image:"./images/i15.jpg",
        user:"Mutima",
        profile:"Journaliste",
        date:"12/4/2024",
        categorie:"culture",
        localisation:"Gitega , Burundi",
    },
    {
        id:"19",
        title:"Lorem ipsum dolor sit amet consectetur ",
        description:"Lorem ipsum dolor sit amet consectetur adipisicing elit.Aperiam Lorem ipsum dolor sit amet consectetur adipisicing elit.AperiamLorem ipsum dolor sit amet consectetur adipisicing elit.AperiamLorem ipsum dolor sit amet consectetur adipisicing elit.AperiamvLorem ipsum dolor sit amet consectetur adipisicing elit.Aperiam",
        boutton1:"Get started",
        boutton2:"Voir plus details",
        image:"./images/i16.jpg",
        user:"Mutima",
        profile:"Journaliste",
        date:"12/4/2024",
        categorie:"culture",
        localisation:"Gitega , Burundi",
    },
    {
        id:"20",
        title:"Lorem ipsum dolor sit amet consectetur ",
        description:"Lorem ipsum dolor sit amet consectetur adipisicing elit.Aperiam Lorem ipsum dolor sit amet consectetur adipisicing elit.AperiamLorem ipsum dolor sit amet consectetur adipisicing elit.AperiamLorem ipsum dolor sit amet consectetur adipisicing elit.AperiamvLorem ipsum dolor sit amet consectetur adipisicing elit.Aperiam",
        boutton1:"Get started",
        boutton2:"Voir plus details",
        image:"./images/i1.jpg",
        user:"Mutima",
        profile:"Journaliste",
        date:"12/4/2024",
        categorie:"culture",
        localisation:"Gitega , Burundi",
    },
    {
        id:"21",
        title:"Lorem ipsum dolor sit amet consectetur ",
        description:"Lorem ipsum dolor sit amet consectetur adipisicing elit.Aperiam Lorem ipsum dolor sit amet consectetur adipisicing elit.AperiamLorem ipsum dolor sit amet consectetur adipisicing elit.AperiamLorem ipsum dolor sit amet consectetur adipisicing elit.AperiamvLorem ipsum dolor sit amet consectetur adipisicing elit.Aperiam",
        boutton1:"Get started",
        boutton2:"Voir plus details",
        image:"./images/i10.jpg",
        user:"Mutima",
        profile:"Journaliste",
        date:"12/4/2024",
        categorie:"culture",
        localisation:"Gitega , Burundi",
    },
    {
        id:"22",
        title:"Lorem ipsum dolor sit amet consectetur ",
        description:"Lorem ipsum dolor sit amet consectetur adipisicing elit.Aperiam Lorem ipsum dolor sit amet consectetur adipisicing elit.AperiamLorem ipsum dolor sit amet consectetur adipisicing elit.AperiamLorem ipsum dolor sit amet consectetur adipisicing elit.AperiamvLorem ipsum dolor sit amet consectetur adipisicing elit.Aperiam",
        boutton1:"Get started",
        boutton2:"Voir plus details",
        image:"./images/i8.png",
        user:"Mutima",
        profile:"Journaliste",
        date:"12/4/2024",
        categorie:"culture",
        localisation:"Gitega , Burundi",
    },
    {
        id:"23",
        title:"Lorem ipsum dolor sit amet consectetur ",
        description:"Lorem ipsum dolor sit amet consectetur adipisicing elit.Aperiam Lorem ipsum dolor sit amet consectetur adipisicing elit.AperiamLorem ipsum dolor sit amet consectetur adipisicing elit.AperiamLorem ipsum dolor sit amet consectetur adipisicing elit.AperiamvLorem ipsum dolor sit amet consectetur adipisicing elit.Aperiam",
        boutton1:"Get started",
        boutton2:"Voir plus details",
        image:"./images/i7.jpg",
        user:"Mutima",
        profile:"Journaliste",
        date:"12/4/2024",
        categorie:"culture",
        localisation:"Gitega , Burundi",
    },
    {
        id:"24",
        title:"Lorem ipsum dolor sit amet consectetur ",
        description:"Lorem ipsum dolor sit amet consectetur adipisicing elit.Aperiam Lorem ipsum dolor sit amet consectetur adipisicing elit.AperiamLorem ipsum dolor sit amet consectetur adipisicing elit.AperiamLorem ipsum dolor sit amet consectetur adipisicing elit.AperiamvLorem ipsum dolor sit amet consectetur adipisicing elit.Aperiam",
        boutton1:"Get started",
        boutton2:"Voir plus details",
        image:"./images/i1.jpg",
        user:"Mutima",
        profile:"Journaliste",
        date:"12/4/2024",
        categorie:"culture",
        localisation:"Gitega , Burundi",
    },


   

   
    
]