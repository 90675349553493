const Propos = () => {
    return ( 
        <>
         <section className="h-96 mt-52">
          <h1>Hello voyage</h1>
          <form>
            <input type="text" placeholder="votre text..."/>
          </form>
          </section>
        </>
     );
}
 
export default Propos;