import { travel } from "../data/data";
import Culture from "../pages/culture";
import { Link } from "react-router-dom";

//icons
import { FaRegUser } from "react-icons/fa";
import { MdBusinessCenter } from "react-icons/md";
import { MdOutlineDateRange } from "react-icons/md";
import { RiRectangleFill } from "react-icons/ri";
import { FaArrowRight } from "react-icons/fa6";
const YoutbeVideo = ({VideoID}) => {
    return ( 
        <>
        <section className="md:max-w-screen-xl p-10  md:max-auto md:p-12 xl:p-20  ">
           <h1 className="absolute md:ml-[320px] xl:ml-[500px] mt-[-16px] ml-[180px] text-center text-white bg-slate-500 px-7 py-2">Explorer plus</h1>
          <div className="grid md:grid-cols-2 md:space-x-24 xl:space-x-32 border-t-2 border-slate-500">
             {/*premier partie*/}
             <div  className="mt-5" >
                <p className="italic font-bold">Commencer a partager votre experience dans le tourisme et culture & voyage partout dans le monde en creant votre article ici commencer avec la creation du compte en haut !</p>
                <iframe
                className="w-full h-64 md:h-96"
                src={`https://www.youtube.com/embed/${VideoID}`}
                title="YouTube Video"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
            ></iframe>
             </div>
             <div className="mt-5">
                     <div>
                        
                     </div>
                </div>
          </div>
           <h1 className="absolute right-0 mr-14"><Link className="flex items-center text-blue-400 underline">Voir plus des articles<FaArrowRight/></Link></h1>
        </section>
      </>
     );
}
 
export default YoutbeVideo;