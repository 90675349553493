const Publier = () => {
    return ( 
        <>
          <section className="h-full flex items-center justify-center mb-36">
                <div className="h-full w-full dark:bg-gray-800  bg-white md:flex md:items-center  relative shadow-md space-x-2 md:space-x-12 md:justify-center  mt-14 border-4 border-dotted border-y-slate-300">

                    <form className="flex items-center justify-center ml-10 shadow-lg rounded-lg flex-col space-y-8 border border-slate-300 w-96 h-ful">
                         <h1 className="font-bold text-2xl">Publication</h1>
                          <p>
                            <label for="nom " className="font-bold">Titre d'article</label>
                            <input type="text" name="nom" placeholder="votre title..." className="px-8  py-1 outline-none border border-slate-600 focus:border-2 focus:border-green-500"/>
                          </p>
                          <p>
                             <label for="image" className="font-bold">Ajouter une photo:</label>
                             <input type="file" alt="choisir une photo" className="px-8  py-1 outline-none  focus:border-2 focus:border-green-500" />
                          </p>
                        <p>
                            <label for="prenom " className="font-bold">Description d'article:</label>
                            <textarea  placeholder="Votre texte..." className="px-14  ml-10 py-24 outline-none border border-slate-600 focus:border-2 focus:border-green-500"></textarea>
                        </p>
                       
                        <p className="font-bold">Categorie:</p>
                        <div className="flex items-center space-x-7">
                            <label>
                                <input type="radio" name="genre" value="sponsorisé" />sponsorisé
                            </label>
                            <label>
                                <input type="radio" name="genre" value="premium" />premium
                            </label>
                            <label>
                                <input type="radio" name="genre" value="gratuit" />gratuit
                            </label>
                        </div>
                        <button className="px-28 text-white py-3 bg-slate-500">Publier</button>
                    </form>
                </div>
           </section>
        </>
     );
}
 
export default Publier;