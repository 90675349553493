import {BrowserRouter,Routes,Route} from 'react-router-dom';
import Navbar from '../common/Navbar';
import Footer from '../common/Footer';
import Home from '../home/Home';
import Publicite from './Publicite';
import Voyage from './Voyage';
import Tourisme from './Tourisme';
import Culture from './culture';
import Propos from './Propos';
import Login from './login';
import Register from './register';
import Publier from './Publier';
import Detail from './detail';

const Pages = () => {
    return ( 
      <BrowserRouter>
          <Navbar/>
          <Routes>
             <Route path='/'  element={<Home/>} />
             <Route index path='culture' element={<Culture/>} />
             <Route  path='voyage' element={<Voyage/>} />
             <Route  path='tourisme' element={<Tourisme/>} />
             <Route  path='publicite' element={<Publicite/>} />
             <Route  path='propos' element={<Propos/>} />
             <Route path='login' element={<Login/>} />
             <Route path='register' element={<Register/>} />
             <Route path='/culture/publier' element={<Publier/>} />
             <Route path="/article/:id" element={<Detail/>} />
          </Routes>
          <Footer/>
      </BrowserRouter>
     );
}
 
export default Pages;