
import { culture1 } from "../data/data";
import { useState } from "react";
import { Link } from "react-router-dom";
import Publicite from "../home/Publicite";
import { useTheme } from "../common/ThemeContext";

//icon
import { IoSearchOutline } from "react-icons/io5";
import { IoCreateOutline } from "react-icons/io5";
import { FaUserCircle } from "react-icons/fa";
import { CiCalendarDate } from "react-icons/ci";
import { FaArrowRight } from "react-icons/fa6";
import { FaRegEye } from "react-icons/fa6";
import { FaRegComment } from "react-icons/fa";

const Tourisme = () => {
  const { isDarkMode } = useTheme();
  //pagination
  const [currentpage,setcurrentpage]=useState(1);
  const recordsPerPage=6;
  const lastIndex=currentpage * recordsPerPage;
  const firstIndex=lastIndex - recordsPerPage;
  const records=culture1.slice(firstIndex,lastIndex);
  const npage=Math.ceil(culture1.length/recordsPerPage);
  const numbers=[...Array(npage + 1).keys()].slice(1);

    return ( 
           <section className="h-full mt-12 ml-20 mb-20 ">
              
                <h1 className="md:text-2xl">Articles sur le <span className="italic font-bold">"Tourisme"</span>: <span>130</span> articles</h1>
                <div className="flex items-center md:space-x-28 md:ml-96 mt-7 ml-[-60px] ">
                  <div className="flex items-center space-x-10">
                  <form action="">
                     <select className="border dark:text-black border-slate-300">
                     <option value="premium" className="dark:text-black">tout</option>
                        <option value="premium" className="dark:text-black">gratuit</option>
                        <option value="sponsorisé" className="dark:text-black">sponsorisé</option>
                        <option value="premium" className="dark:text-black">premium</option>
                     </select>
                  </form>

                 <form className="flex items-center">
                    <input id="text" name="text" placeholder="recherhcer ..." className="mt-1 dark:text-black block md:w-full w-24 border border-gray-300 rounded-md shadow-sm p-2 focus:outline-none focus:ring focus:ring-blue-300" />
                    <button className=" dark:text-white"><IoSearchOutline size={28}/></button>
                 </form>
                 <div>
                    <button className="bg-slate-500 flex items-center  px-4 text-white py-2"><Link to="publier">Creer un Article<IoCreateOutline/></Link></button>
                 </div>
                 </div>
              </div>
               {/* Articles*/}
               <div className="grid md:grid-cols-2 xl:grid-cols-3 gap-4 md:gap-4 xl:gap-4 mt-12">
                  {
                    records.map((item,index)=>{
                      const desc=`${item.description.split("").slice(0,60).join("")} ...`;
                      return(
                        <div className="border border-slate-300 shadow-sm w-80 h-96  rounded-tl-lg rounded-tr-lg space-x-6">
                           <img src={item.image} alt="" className="w-full h-48 rounded-tl-lg rounded-tr-lg" />
                           <div className="flex items-center space-x-36">
                           <h1 className="flex items-center space-x-3" ><FaUserCircle/>{item.user}</h1>
                           <p>{item.pre}</p>
                           <p></p>
                           </div>
                           <h1 className="font-bold"><Link to={`/article/${item.id}`}>{item.title}</Link></h1>
                           <p className="w-72">{desc}</p>
                           <p className="flex items-center space-x-3"><CiCalendarDate/>{item.date}</p>
                           <div className="flex items-center space-x-8 mt-4">
                              <h1 ><Link to={`/article/${item.id}`} className="flex items-center space-x-3">Learn More<FaArrowRight /></Link></h1>
                              <div className="flex items-center space-x-2">
                                  <p className="flex items-center space-x-3"><FaRegEye/>1.2K </p>
                                  <p>|</p>
                                  <p className="flex items-center space-x-3"><FaRegComment/>5</p>
                              </div>
                           </div>
                        </div>
                      )
                    })
                  } 
               </div>
            
    {/*Html pour pagination */}
               <nav >
                  <ul className="flex items-center justify-center mt-8">
                     <li className="px-5 py-2 border border-slate-300"><a href="#" onClick={prePage}>Prev</a></li>
                     {
                       numbers.map((n,i)=>(
                           <li className={`${currentpage===n?'bg-green-600 px-5 py-2 border border-slate-300':'px-5 py-2 border border-slate-300'}`} key={i}>
                            <a href="#" onClick={()=>changeCPage(n)}>{n}</a>
                           </li>
                       ))
                     }
                      <li className="px-5 py-2 border border-slate-300"><a href="#" onClick={nextPage}>next</a></li>
                  </ul>
               </nav>
           </section>
           
     );

     //les fonctions pour paginations
     function prePage()
     {
        if(currentpage !== firstIndex)
        {
           setcurrentpage(currentpage - 1);
        }
     }
     function nextPage()
     {
       if(currentpage !== lastIndex)
       {
         setcurrentpage(currentpage + 1);
       }
     }
     function changeCPage(id)
     {
         setcurrentpage(id);
     }
}
 
export default Tourisme;