const Footer = () => {
    return (
        <>
          <footer className="bg-blue-950 w-full sm:w-full sm:h-full h-full text-white">
               <div className="grid sm:grid-cols-3 items-center sm:ml-60 justify-center">
                  
                  <ul>
                  <h1 className="font-bold p-2">A propos </h1>
                      <li>Lorem ipsum dolor sit amet.</li>
                      <li>Lorem ipsum dolor sit amet.</li>
                      <li>Lorem ipsum dolor sit amet.</li>
                  </ul>

                  
                  <ul>
                  <h1 className="font-bold p-2">Notre mission</h1>
                      <li>Lorem ipsum dolor sit amet.</li>
                      <li>Lorem ipsum dolor sit amet.</li>
                      <li>Lorem ipsum dolor sit amet.</li>
                  </ul>

                  
                  <ul>
                  <h1 className="font-bold p-2">Suivez nous</h1>
                      <li>Lorem ipsum dolor sit amet.</li>
                      <li>Lorem ipsum dolor sit amet.</li>
                      <li>Lorem ipsum dolor sit amet.</li>
                  </ul>
               </div>
          </footer>
        </>
    );
}
 
export default Footer;