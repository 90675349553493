const Register = () => {
    return ( 
        <>
           <section className="h-full flex items-center justify-center mb-36">
                <div className="h-full w-full dark:bg-gray-800  bg-white md:flex md:items-center  relative shadow-md space-x-2 md:space-x-12 md:justify-center  mt-14 border-4 border-dotted border-y-slate-300">
                <div className="ml-10">
                        <h1 className="font-bold">les etapes pour l'inscription </h1>
                        <ol className="italic list-decimal space-y-5">
                            <li>Donner votre nom | exemple:Alexis</li>
                            <li>Donner votre Prenom | exemple:Ciza</li>
                            <li>Donner votre Profession | exemple:journaliste</li>
                            <li>Donner votre votre pays de residence | exemple:Burundi</li>
                            <li>Donner votre sexe | exemple:Homme</li>
                        </ol>
                    </div>
                    <form className="flex ml-10 items-center justify-center shadow-lg rounded-lg flex-col space-y-8 border border-slate-300 w-96 h-ful">
                         <h1 className="font-bold">Inscription</h1>
                          <p>
                            <label for="nom">Nom:</label>
                            <input type="text" name="nom" placeholder="votre nom..." className="px-8  py-1 outline-none border border-slate-600 dark:text-black focus:border-2 focus:border-green-500"/>
                          </p>
                        <p>
                            <label for="prenom">Prenom:</label>
                            <input type="text" name="prenom" placeholder="votre prenom..." className="px-8 dark:text-black py-1 outline-none border border-slate-600 focus:border-2 focus:border-green-500" />
                        </p>
                        <p>
                            <label for="prefession">Profession:</label>
                            <input type="text" name="profession" placeholder="votre profession..." className="px-8 dark:text-black py-1 outline-none border border-slate-600 focus:border-2 focus:border-green-500" />
                        </p>
                        <p>
                            <label for="pays">Pays:</label>
                            <input type="text" name="pays" placeholder="votre Pays..." className="px-8 dark:text-black py-1 outline-none border border-slate-600 focus:border-2 focus:border-green-500" />
                        </p>
                        <p>
                            <label for="mail">Votre e-mail:</label>
                            <input type="text" name="mail" placeholder="votre e-mail..." className="px-8 dark:text-black  py-1 outline-none border border-slate-600 focus:border-2 focus:border-green-500" />
                        </p>
                        <p>
                            <label for="password">Pays:</label>
                            <input type="password" name="password" placeholder="votre mot de passe..." className="px-8 dark:text-black  py-1 outline-none border border-slate-600 focus:border-2 focus:border-green-500" />
                        </p>
                        <p>Sexe:</p>
                        <div className="flex items-center space-x-7">
                            <label>
                                <input type="radio" name="genre" value="homme" />Homme
                            </label>
                            <label>
                                <input type="radio" name="genre" value="femme" />Femme
                            </label>
                        </div>
                        <button className="px-28 text-white py-3 bg-green-500">S'inscrire</button>
                    </form>
                </div>
           </section>
        </>
     );
}
 
export default Register;