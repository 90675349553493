import logo from './logo.svg';
import './App.css';
import Pages from './components/pages/pages';
import { useTheme } from './components/common/ThemeContext';

function App() {
  const { isDarkMode } = useTheme();
  return (
    <div className={`min-h-screen transition-colors duration-300 ${isDarkMode ? 'bg-gray-800 text-white' : 'bg-white text-black'}`}>
    <div>
      
        <Pages/>
       
    </div>
    </div>
  );
}

export default App;
