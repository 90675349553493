import { logo,menu1,menu2} from "../data/data";
import { Link } from "react-router-dom";
import { useState } from "react";
import { useTheme } from "./ThemeContext";

//icons
import { FaSun, FaMoon } from 'react-icons/fa';
import { FaAlignJustify } from "react-icons/fa6";


//Bouton de Dark mode et light mode
const ThemeToggleButton = () => {
   const { isDarkMode, toggleTheme } = useTheme();

   return (
      <button 
      onClick={toggleTheme} 
      className="p-2 rounded bg-gray-200 dark:bg-gray-700 flex items-center">
      {isDarkMode ? <FaMoon className="text-xl  text-blue-500" /> : <FaSun className="text-xl text-blue-500" />}
  </button>
   );
};

const Navbar = () => {

   const [selected,setselected]=useState(null);

   const [open,setopen]=useState(false);
    
   const handle=(id)=>{
      setselected(id);
   }

    return ( 
        <>
        
           <header className=" h-full sticky top-0 left-0 z-40 ">
              <div  className="bg-white dark:border-none dark:bg-gray-700 dark:text-gray-100 border-b w-full border-slate-100 flex items-center h-20 justify-between">
                     <div  className="flex items-center justify-between space-x-36">
                  <Link to='/'><img src='./ttcv.png' alt="" className="w-24 h-24 md:ml-44 ml-32  " /></Link>
                     <h1 className="cursor-pointer md:hidden" onClick={()=>setopen(!open)}><FaAlignJustify size={24}/></h1>
                    </div>
                  <ul className="flex items-center space-x-7  md:mt-0 md:mr-24 mt-[120px] md:relative absolute ml-[30px]">
                     <li>
                        <form>
                           <select className="dark:text-black">
                              <option value="Kirundi">Kirundi</option>
                              <option value="Français">Français</option>
                              <option value="Anglais">Anglais</option>
                           </select>
                        </form>
                     </li>
                     <li className=" md:bg-blue-500 px-5 py-2"><Link to='login' >connexion</Link></li>
                     <li className="md:bg-green-500 px-5 py-2"><Link  to="register">S'incrire</Link> </li>
                     <li><Link to="/"> <ThemeToggleButton /></Link></li>
                  </ul>
              </div>
              <nav className="bg-white dark:bg-gray-700   h-11 w-full border-b border-slate-300 shadow-lg">
                   <ul className={`md:flex xl:flex xl:items-center transition duration-300 ease-in  opacity-0 ${open?'top-20px opacity-100 ':'top-[-490px] md:top-0'}  md:opacity-100   relative  w-full dark:text-black md:dark:text-white bg-white space-y-5 md:space-y-0  md:dark:bg-slate-700 text-black md:items-center justify-center sm:space-x-16   space-x-8`}>
                     {
                        menu1.map((item,index)=>(
                           <li key={index} onClick={()=>handle(item.id)} className={`sm:px-5 sm:py-2 pt-3 ml-10 ${selected===item.id?'border-b-4   border-red-500 text-black md:dark:text-white ':'  '}   `}><Link to={item.url} >{item.text}</Link></li>
                        ))
                     }
                   </ul>
              </nav>
           </header>
        </>
     );
}
 
export default Navbar;