const Login = () => {
    return ( 
        <>
           <section className="h-full flex items-center justify-center mb-36">
                <div className="h-full w-full dark:bg-gray-800  bg-white md:flex md:items-center  relative shadow-md space-x-2 md:space-x-12 md:justify-center  mt-14 border-4 border-dotted border-y-slate-300">
                    <div className="ml-10">
                        <h1 className="font-bold">Commencer à partager votre Article </h1>
                        <p className="w-96 p-4 italic">Vous avez cherché là où vous pouvez partager votre expérience dans le domaine de Tourisme , de 
                            voyage , de culture pour different pays du monde ici c'est votre endroit commencer
                             de maintenant  en créant votre propre compte pour créer vos articles  où les visiteurs peuvent 
                             lire votre articles et les partager en créant du compte vous aurez l'accès à des contenus premium et
                              vous pouvez passer votre publicité ici </p>
                    </div>
                    <form className="flex ml-10 items-center justify-center shadow-lg rounded-lg flex-col space-y-8 border border-slate-300 w-96 h-96">
                         <h1 className="font-bold">Connexion</h1>
                        <input type="text" placeholder="adresse e-mail..." className="px-16 dark:text-black  py-3 outline-none border border-slate-600 focus:border-2 focus:border-blue-500" />
                        <input type="password" placeholder="votre mot de passe" className="px-16 dark:text-black  py-3 outline-none border border-slate-600 focus:border-2 focus:border-blue-500" />

                        <button className="px-28 text-white py-3 bg-blue-500">Se connecter</button>
                    </form>
                </div>
           </section>
        </>
     );
}
 
export default Login;