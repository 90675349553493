import { start } from "../data/data";
import { GrLinkNext } from "react-icons/gr";
import Typewriter from 'typewriter-effect';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


const Publicite = () => {
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay:true,
    autoplaySpeed:25000,
  };
  
 
    return ( 
      <section className="">
      {/*composant pour les premiere image avec un slide */}
          <div>
             <div className=" flex flex-col space-y-4 p-4">
             <Slider {...settings} className="">
               {
                 start.map((value,index)=>
                   
                    {
                  const desc=`${value.description.split("").slice(0,100).join("")}...`;
                      return(
                        <div key={index} className="bg-gradient-to-r from-slate-900  rounded-xl h-96 w-full bg-cover bg-center  relative">
                            <img src={value.image} alt=""  className="w-full h-full rounded-xl object-cover absolute mix-blend-overlay" />
                             <div  className="p-24" >
                                <h1 className="text-white md:text-4xl text-2xl  xl:text-4xl font-bold">{value.title}</h1>
                                <p className="text-slate-100 md:text-2xl   xl:text-2xl font-light mt-5" >
                                  <Typewriter options={{strings:[`${desc}`], autoStart:true,autoStartSpeed:2000,loop:true}} />
                                  </p>
                                <button  className="bg-red-600 flex items-center px-5 py-2 cursor-pointer text-white">{value.boutton2}<GrLinkNext/></button>
                            </div>
                        </div>
                      )
                    }
                 )
               } 
               </Slider>
              
             </div>
          </div>
    </section>
     );
}
 
export default Publicite;