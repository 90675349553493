import { travel } from "../data/data";
import Culture from "../pages/culture";
import { Link } from "react-router-dom";

//icons
import { FaRegUser } from "react-icons/fa";
import { MdBusinessCenter } from "react-icons/md";
import { MdOutlineDateRange } from "react-icons/md";
import { RiRectangleFill } from "react-icons/ri";
import { FaArrowRight } from "react-icons/fa6";


const Tourisme = () => {
    return ( 
        <>
        <section className="md:max-w-screen-xl p-10  md:max-auto md:p-12 xl:p-20  ">
           <h1 className="absolute md:ml-[320px] xl:ml-[500px] mt-[-16px] ml-[180px] text-center text-white bg-blue-500 px-7 py-2">Tourisme</h1>
          <div className="grid md:grid-cols-2 md:space-x-24 xl:space-x-32 border-t-2 border-slate-500">
             {/*premier partie*/}
             <div  className="mt-5" >
                {
                  travel.map((item,index)=>{
                      const desc=`${item.description.split("").slice(0,200).join("")}...`;
                      return(
                          <div className="md:flex xl:flex xl:items-center md:items-center space-x-3  ">
                          <div>
                              <img src={item.image} className="md:w-44 xl:w-44 xl:h-44 md:h-44 m-3 w-full " alt="" />
                          </div>
                          <div className="md:w-96 xl:w-96 w-full ">
                              <h1 className="font-bold text-2xl"><Link to={`/article/${item.id}`}>{item.title}</Link></h1>
                              <p className="text-gray-400">{desc} <Link to={`/article/${item.id}`} className="underline text-blue-500">voir plus</Link></p>
                              <p className="italic">Tags:<span className="bg-blue-500 px-3 py-0 text-white">{item.categorie}</span></p>
                               <div className="flex items-center space-x-4">
                              <p className="flex items-center "><FaRegUser className="m-2"/>{item.user}</p>
                              <p className="flex items-center "><MdBusinessCenter className="m-2"/>{item.profile}</p>
                               </div>
                               <p className="flex items-center "><MdOutlineDateRange className="m-2"/>{item.date}</p>
                          </div>
                          </div>
                      )
                  })
                }
                
             </div>
             <div className="mt-5">
                     <div>
                        
                     </div>
                </div>
          </div>
           <h1 className="absolute right-0 mr-14"><Link className="flex items-center text-blue-400 underline">Voir plus des articles<FaArrowRight/></Link></h1>
        </section>
      </>
     );
}
 
export default Tourisme;